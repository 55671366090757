.report-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding: 30px;
}

.report-sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    overflow: scroll;
    scrollbar-width: none;
}

.report-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem 1rem;
}

.report-container>h1 {
    text-align: center;
}

.report-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    border-radius: 33px;
    background: #ffffff;
    box-shadow: 5px 5px 20px #d9d9d9,
        -5px -5px 20px #ffffff;
    padding: 1.5rem 1rem;
}

.score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.report-summary>p {
    text-align: justify;
    font-size: 0.8rem;
    padding: 0.8rem;
}

.score-circle {
    width: 150px;
    height: 150px;
    border: 5px solid #297ec4;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    text-align: center;
}

.score-circle>h1 {
    font-size: 2.5rem;
}

.report-description {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem 3rem;
    font-size: 0.9rem;
}

.checklist-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 33px;
    background: #ffffff;
    box-shadow: 5px 5px 20px #d9d9d9,
        -5px -5px 20px #ffffff;
}

.checklists {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem 2rem;
}

.checklist-img {
    width: 1.25rem;
}

.checklist-item>strong {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 0.5rem;
}

.checklist-box {
    width: 230px;
    min-height: 90px;
    background-color: #1453A1;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
    font-size: 0.9rem;
}

.checklist-box>p {
    display: flex;
    flex-wrap: wrap;
}

.checklist-box button {
    background-color: transparent;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.readability,
.estimated-time,
.action-verb {
    width: 280px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    background: #FC6736;
    color: #ffffff;
}

.additional-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.suggestions-section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    border-radius: 33px;
    background: #ffffff;
    box-shadow: 5px 5px 20px #d9d9d9,
        -5px -5px 20px #ffffff;
}

.suggestions-section>p {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.suggestions-section>p>span {
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 1rem;
    background-color: #1453A1;
    color: #ffffff;
}

#dark-report-summary,
#dark-checklist-container,
#dark-suggestions-section {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.report-btns {
    display: flex;
    gap: 2rem;
}

.embed-container embed {
    position: fixed;
    top: 3rem;
    right: 0;
    width: 40%;
    height: calc(100vh - 100px);
    overflow: hidden;
    margin: 1.5rem;
    scrollbar-width: none;
    background-color: transparent;
}

.link{
    word-break: break-all;
}

@media screen and (max-width: 1000px) {
    .embed-container {
        display: none;
    }
    .checklist,
    .report-description {
        width: 100%;
    }
    .embed-container {
        width: 100%;
    }
    .report-sidebar{
        padding: 0;
    }
    
    .report-sidebar{
        width: 100%;
    }
}

@media screen and (max-width: 655px) {
    .checklist-item,
    .checklist-box,
    .report-description,
    .readability,
    .estimated-time,
    .action-verb {
        width: 100%;
    }
    .embed-container {
        width: 100%;
    }
    .report-sidebar{
        padding: 0;
    }
    
    .report-sidebar{
        width: 100%;
    }
    .report-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}