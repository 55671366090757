.uploader-page {
    width: 100%;
    height: 90dvh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-image: url(../assets/cool-background.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#upload-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
}

.uploading-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 1.5rem 1rem; */
}

.intro-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin: 0 1rem;
    text-align: center;
    gap: 0.5rem;
}

.intro-section p{
    max-width: 500px;
}

.intro-animation {
    max-width: 800px;
}

.resume-input[type="file"] {
    position: relative;
    border-radius: 4px;
}

.resume-input[type="file"]::file-selector-button {
    width: 136px;
    height: 40px;
    padding: 0 16px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    color: transparent;
    border-radius: inherit;
    transition: background-color 200ms;
}

.resume-input[type="file"]::before {
    position: absolute;
    top: 10px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

.resume-input[type="file"]::after {
    position: absolute;
    top: 11px;
    left: 40px;
    color: #0964b0;
    content: "Upload File";
}

.resume-uploader {
    padding: 20px;
    border: 2px dashed black;
    border-radius: 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0;
    background: #ffffff;
}

.resume-uploader p{
    font-size: 0.6rem;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.description>h2 {
    text-align: center;
}

.description-input {
    width: 295px;
    height: 200px;
    padding: 20px;
    margin: 1rem 0;
    border: none;
    border-radius: 37px;
    background: #ffffff;
    box-shadow: 3px 3px 15px #9e9e9e,
        -3px -3px 15px #ffffff;
    scrollbar-width: none;
    outline: none;
    resize: none;
}

.recent-data-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
}

.recent-data-section>input {
    padding: 20px;
}

.hero-btn-container{
    display: flex;
    gap: 1rem;
}

#dark-uploader-page {
    background-image: url(../assets/cool-background-dark.svg);
}

#dark-resume-uploader,
#dark-description-input {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: #ffffff;
    border-radius: 10px;
}

#dark-intro-section {
    color: #fff;
}

@media screen and (max-width: 900px) {
    .intro-section {
        font-size: 0.8rem;
        color: #000;
    }

    .uploader-page {
        height: 100%;
        flex-direction: column;
        font-size: 0.8rem;
        gap: 2rem;
        padding: 2rem 0;
    }

    .description>h2 {
        color: #fff;
    }
}

@media screen and (min-height: 1000px) {
    .uploader-page {
        flex-direction: column;
        height: 100%;
        padding: 30px;
    }
}