* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,body{
  overflow-x: hidden;
}


body {
  width: 100%;
  height: 100%;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: ghostwhite;
}

.loader-page {
  width: 100%;
  height: 85dvh;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-report{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.btn {
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  background-color: #1453A1;
  color: #fff;
}

a {
  text-decoration: none;
  color: #000;
}

.alert-box {
  position: fixed;
  top: 15%;
  left: 10px;
  transition: all 1s ease-in-out;
  z-index: 20;
}

#dark-body{
  background-color: #111010;
  color: #ffffff;
}

@media screen and (max-width: 670px) {
  .alert-box{
    font-size: 0.8rem;
  }
  .loader-page h3{
    max-width: 180px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-height: 1000px) {
  body{
    width: 100%;
    font-size: 1.3rem;
  }
}
